/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  --primary-color: rgb(6, 182, 212);
  --grey: #888888;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.topic {
  padding: 1.5rem;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0rem;
  color: var(--primary-color);
  text-align: center;
}

.sidebar {
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100vh;

}

.sidebar-left {
  width: 20%;
  /* height: 100vh; */
}

.sidebar-button {
  width: 100%;
  height: 100vh;
  text-align: start !important;
}

.text-align-start {
  text-align: start !important;
}

.content {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: 20vw;
}

.sub-topic {
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0rem;
  color: var(--grey);
  text-align: center;
}

.text-grey {
  color: var(--grey);
}

.filer-icon {
  display: none;
}

.top-Btn {
  display: block;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: var(--grey);
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

.none {
  display: none;
}

.width90 {
  width: 90% !important;
}

.width70 {
  width: 70%;
}

.width60 {
  width: 60%;
}

.notMatch {
  padding: 2rem;
  background-color: var(--bs-secondary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
}

.message>div {
  margin: 1rem 0;
}


.message>div>span {
  font-weight: bold;
}

/* mobile version */
@media screen and (max-width: 980px) {
  .direction {
    flex-direction: column;
  }

  .filer-icon {
    visibility: visible;
    display: flex;
    position: fixed;
    z-index: 1;
  }

  .topic {
    font-size: 2rem;
  }
  .sub-topic {
    font-size: 1rem;
  }

  .sidebar-left {
    display: none;
  }

  .content {
    width: 100%;
    margin-left: 0;
  }

  .box {
    width: -webkit-fill-available;
  }
}
